import React, {
  useState,
  useEffect,
  useContext
} from 'react'
import CoDialog from '../../../../components/Dialog/CoDialog'
import CoTextField from '../../../../components/TextField/CoTextField'
import CoListTextField from '../../../../components/ListTextField/CoListTextField'
import { useWindowSize } from '../../../../components/windowSize/use-window-size'
import CoButton from '../../../../components/Buttons/CoButton'
import helpers from '../../../../utils/helpers'
import analytics from '../../../../utils/analytics'
import CheckOut from '../../services/CheckOut.service'
import { OrderformContext } from '../../contexts/orderform.provider'
import BUSINESS_TYPE from './giros.json'
import REGIONS from './regions.json'

const ERRORES: { [key: string]: any } = {
  corporateAddress: 'Ingrese una dirección',
  corporateDocument: 'Ingresa un RUT válido',
  corporateName: 'Ingresa una Razón Social válida',
  corporatePhone: 'Digite un número válido',
  purchaseOrder: 'Deben ser máximo 15 caracteres',
  corporateGiro: 'Seleccione un giro',
}

let GIROS: { [key: string]: unknown } = {}

BUSINESS_TYPE.businessType.sort().forEach((item) => {
  GIROS[item] = item
})

const Invoice = ({
  isOpen,
  setIsOpen,
  setValue,
  validateSection,
  handleChangeRadio,
  setSelectedPaymentMethod,
  formCorporateData,
  setFormCorporateData,
  setCheckedSwitch,
  isChecked
}: any) => {
  const {
    orderform,
    updateOrderFormData,
    setLoading,
    setMethodTransfer
  } = useContext(OrderformContext)
  const { clientProfileData, corporateData } = orderform

  const isMobile = useWindowSize().width <= 711;
  const {
    corporateAddress = '',
    corporateComplement = '',
    corporateDocument = '',
    corporateGiro = '',
    corporateName = '',
    corporateNeighborhood = '',
    corporatePhone = '',
    corporateState = '',
    documentType = '',
    purchaseOrder = '',
  } = formCorporateData || {};

  const [errorMessage, setErrorMessage] = useState({
    corporateAddress: '',
    corporateDocument: "",
    corporateName: "",
    purchaseOrder: "",
    corporatePhone: '',
    corporateGiro: '',
  });
  const [isValidForm, setIsValidForm] = useState(false);

  const [isValid, setIsValid] = useState({
    corporateAddress: corporateAddress?.length > 0 ? true : false,
    corporateDocument: helpers.checkRut(helpers.rutUnfilter(corporateDocument)),
    corporateName: corporateName?.length > 0 ? true : false,
    corporatePhone: corporatePhone?.length === 8 ? true : false,
  });

  const [checked] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const REGIONES = Object.fromEntries(REGIONS.regions.map(({ region }) => [region, region]))


  let comunas: any[] = [];

  if (corporateState) {
    REGIONS?.regions?.find(({ region }) => region === corporateState)?.comunas?.forEach(({ name }) => {
      comunas?.push([name, name]);
    })
  }
  const COMUNAS = Object.fromEntries(comunas);

  const handleCloseDialog = () => {
    if (!isValidForm) {
      let newFormCorporateData: any = {}
      Object.entries(formCorporateData).forEach((item) => {
        newFormCorporateData[item[0]] = ''
      })
      setFormCorporateData(corporateData || newFormCorporateData);
    }
    setCheckedSwitch(false)
    setIsOpen(false);
  };

  /**
   * Change event handler for corporate data form fields
   * @param {any} event:{target:{value:any;name:any}}
   * @returns {any}
   * */
  const handleChange = (event: { target: { value: any; name: any } }) => {
    const { value, name } = event.target;

    switch (name) {
      case "corporateDocument":
        if (value.length > 9) return null;

        //Validacion caracteres
        if (!/^(\d*k?)$/.test(value)) return null;

        setFormCorporateData({
          ...formCorporateData,
          corporateDocument: value,
        });
        break;
      case "purchaseOrder":
        // Solo alfanumericos Max 15
        if (value.length >= 16 || value.match(/(\W){1,15}/gim)) return null
        setFormCorporateData({
          ...formCorporateData,
          [name]: value,
        })
        break;
      case "corporateGiro":
        setFormCorporateData({
          ...formCorporateData,
          [name]: value,
        });
        break;
      case "corporateAddress":
        if (!/^[a-z\ 0-9]*$/gim.test(value.at(-1))) return null
        setFormCorporateData({
          ...formCorporateData,
          [name]: value,
        });
        break;
      case "corporatePhone":

        if (/^(\d*)$/.test(value) && value.length < 9) {
          setFormCorporateData({
            ...formCorporateData,
            [name]: value,
          });
        }
        break;
      case "corporateState":
        setFormCorporateData({
          ...formCorporateData,
          city: "",
          corporateNeighborhood: "",
          [name]: value,
        });
        break;
      case "corporateNeighborhood":
        const PROVINCIA_COMUNAS = REGIONS?.regions?.find((entrada) => entrada.region === corporateState)
        const getRegion = PROVINCIA_COMUNAS?.comunas?.find(({ name }) => name === value)
        let positionCode: string[] = []
        PROVINCIA_COMUNAS?.comunas?.forEach(comuna => {
          if (!positionCode.includes(comuna.code)) positionCode.push(comuna.code);
        })
        let key = positionCode?.indexOf(getRegion?.code || '')
        let selectedCity = PROVINCIA_COMUNAS?.provincias?.[key] ?? ''
        if (!selectedCity) selectedCity = PROVINCIA_COMUNAS?.provincias[0] as string

        setFormCorporateData({
          ...formCorporateData,
          city: selectedCity,
          [name]: value,
        });
        break

      case 'corporateComplement':
        if (/^[\w ]*$/.test(value)) {
          setFormCorporateData({
            ...formCorporateData,
            [name]: value
          })
        }
        break
      default: setFormCorporateData({
        ...formCorporateData,
        [name]: value,
      });
        break;
    }
  }

  /**
  * Focus event handler for corporate data form fields
  * @param {object} event - The DOM event object
  * @param {object} event.target - The object that fired the event
  * @param {string} event.target.name - The name of the object that fired the event
  */
  const handleOnFocus = (event: { target: { value: any; name: any } }) => {
    const { name } = event.target;
    if (name === "corporateDocument") {
      const unformattedCorporateDocument = helpers.rutUnfilter(corporateDocument);
      setFormCorporateData({
        ...formCorporateData,
        corporateDocument: unformattedCorporateDocument,
      });
      setIsValid({
        ...isValid,
        [name]: false,
      });
    } else {
      setIsValid({
        ...isValid,
        [name]: false,
      });
    }
  }

  /**
   * Submit the form with the updated corporate data.
   * @returns {any}
   * */
  const handleSubmit = async () => {
    setIsLoading(true)
    analytics.interactiveEvent({
      action: 'boton guardar datos de facturacion', label: 'guardar datos'
    })
    let newCorporateData
    try {
      const response = await CheckOut.updateBill({
        ...formCorporateData,
        address: formCorporateData.corporateAddress,
        category: null,
        city: formCorporateData?.city,
        corporateCity: formCorporateData?.city || corporateNeighborhood,
        complement: corporateComplement || null,
        corporateDocument: corporateDocument,
        corporatePhone: corporatePhone,
        document: clientProfileData.document,
        documentType: clientProfileData.documentType || documentType || "rutCHL",
        email: clientProfileData.email,
        firstName: clientProfileData.firstName,
        giro: corporateGiro,
        giroId: null,
        lastName: clientProfileData.lastName,
        neighborhood: corporateNeighborhood,
        phone_alt: null,
        phone: `${clientProfileData.phone}`,
        state: corporateState,
        stateInscription: corporateDocument,
        corporateTradeName: corporateName,
        tradeName: corporateName,
      }).catch(() => {
        setIsLoading(false)
      })
      if (response?.customData) {
        newCorporateData = JSON.parse(
          response?.customData?.customApps?.find(
            (customApp: any) => customApp.id === "clientprofiledata"
          ).fields?.corporateData
        )
      }
    } catch (error) {
      console.log('error', error)
    } finally {
      setFormCorporateData(newCorporateData)
      updateOrderFormData({ corporateData: newCorporateData })
      updateOrderformAlvi()
      setIsOpen(false)
      setIsLoading(false)
      setCheckedSwitch(true)
    }
  }

  const updateOrderformAlvi = async () => {
    setIsLoading(true)
    setLoading(true)
    CheckOut.ApiCheckout()
      .then(async (response: any) => {
        if (response) {
          const dataInvoice = JSON.parse(
            response?.customData?.customApps?.find(
              (customApp: any) => customApp.id === "clientprofiledata"
            ).fields?.corporateData
          )
          updateOrderFormData({
            hideTransfer: response?.hideTransfer,
            corporateData: dataInvoice,
          })
          if (response?.hideTransfer) {
            setMethodTransfer(false)
            setLoading(false)
            setValue('oneClick');
            validateSection('oneClick');
            handleChangeRadio('oneClick')
            setSelectedPaymentMethod('oneClick')
          }
        }
      }).catch(() => {
        setIsLoading(false)
        setLoading(false)
        setMethodTransfer(false)
      })
  }


  /**
  * Handle onBlur event for corporate document and phone fields.
  * @param {Object} event - The event object.
  * @param {any} event:{target:{value:any;name:any}} -The name of the input field and the value of the input field.
  * @returns {any}
  *  */
  const handleOnBlur = (event: { target: { value: any; name: any } }) => {
    const { value, name } = event.target;
    let isValidObject: any = {};

    switch (name) {
      case "corporateDocument":
        const hasFormat = /[.-]/.test(corporateDocument)
        const formattedCorporateDocument = hasFormat ? corporateDocument : helpers.rutFilter(corporateDocument);
        const cleanDocument = hasFormat ? helpers.rutUnfilter(corporateDocument) : corporateDocument
        const isValidFormat = helpers.checkRut(cleanDocument)

        setErrorMessage({
          ...errorMessage,
          [name]: isValidFormat ? "" : ERRORES[name],
        });
        setIsValid((prev) => ({ ...prev, [name]: isValidFormat }));
        setFormCorporateData({
          ...formCorporateData,
          corporateDocument: formattedCorporateDocument,
        });
        break;

      case 'corporatePhone':
        if (value.length < 9) {
          setErrorMessage({
            ...errorMessage,
            [name]: ERRORES[name],
          });
        }
        if (value.length === 8) {
          setErrorMessage({
            ...errorMessage,
            [name]: "",
          });
        }
        break

      default:
        if (value.length > 0) {
          setErrorMessage({
            ...errorMessage,
            [name]: "",
          });
          isValidObject = {
            ...isValid,
            [name]: true,
          };
        } else {
          setErrorMessage({
            ...errorMessage,
            [name]: ERRORES[name],
          });
          isValidObject = {
            ...isValid,
            [name]: false,
          };
        }
        setIsValid(isValidObject);
        break;
    }
  };

  // general validation of the form, to activate the button
  const formValidation = () => {
    if (
      helpers.checkRut(helpers.rutUnfilter(corporateDocument)) &&
      corporateAddress && corporateAddress?.length > 5 &&
      corporateName && corporateName?.length > 0 &&
      corporateDocument &&
      corporateGiro &&
      BUSINESS_TYPE.businessType.includes(corporateGiro) &&
      corporateNeighborhood &&
      corporatePhone && corporatePhone?.length === 8 &&
      corporateState
    ) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);

      if (corporateGiro) {
        setErrorMessage({
          ...errorMessage,
          'corporateGiro': !BUSINESS_TYPE.businessType.includes(corporateGiro) ? ERRORES.corporateGiro : "",
        });
      }

      if (isChecked && corporateDocument && !isOpen) setIsOpen(true)
    }

    if (corporateDocument?.length === 9 && !/[.-]/.test(corporateDocument)) {
      setFormCorporateData((prev: object) => {
        return { ...prev, corporateDocument: helpers.rutFilter(corporateDocument) }
      })
    }
  };


  useEffect(() => {
    formValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCorporateData]);

  return (
    <CoDialog
      isMobile={isMobile}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      title="Datos de Facturación"
      handleClose={handleCloseDialog}
    >
      <div className="dialog-form">

        {/* 1.  Rut de empresa */}
        <CoTextField
          autoFocus={true}
          errorMessage={errorMessage?.corporateDocument}
          isValid={isValid?.corporateDocument}
          label="RUT de la empresa"
          name="corporateDocument"
          value={corporateDocument}
          handleChange={handleChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
        />

        {/* 2. Razón social */}
        <CoTextField
          errorMessage={errorMessage.corporateName}
          isValid={isValid.corporateName}
          label="Razón social"
          name="corporateName"
          value={corporateName}
          handleChange={handleChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
        />

        {/* 3. Giro industrial */}
        <CoListTextField
          items={GIROS}
          errorMessage={errorMessage.corporateGiro}
          name="corporateGiro"
          placeholder="Giro industria"
          value={corporateGiro}
          handleOnBlur={handleOnBlur}
          handleChange={handleChange}
        />

        {/* 4. Dirección de facturación */}
        <CoTextField
          errorMessage={errorMessage.corporateAddress}
          isValid={isValid.corporateAddress}
          label="Dirección de la facturación o de la empresa"
          name="corporateAddress"
          value={corporateAddress}
          handleChange={handleChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
        />

        {/* 5. Región de facturación */}
        <CoListTextField
          items={REGIONES}
          name="corporateState"
          placeholder="Región"
          readOnly={checked}
          value={corporateState}
          handleChange={handleChange}
        />

        {/* 6. Comuna */}
        <CoListTextField
          items={COMUNAS}
          name="corporateNeighborhood"
          placeholder="Comuna"
          readOnly={checked}
          value={corporateNeighborhood}
          handleChange={handleChange}
        />

        {/* 7. Dpto/Casa/Oficina */}
        <CoTextField
          errorMessage={""}
          label="Departamento/Casa/Oficina (opcional)"
          name="corporateComplement"
          value={corporateComplement}
          handleChange={handleChange}
          handleOnBlur={handleOnBlur}
        />

        {/* 8.  Teléfono */}
        <CoTextField
          errorMessage={errorMessage.corporatePhone}
          isValid={isValid.corporatePhone}
          label="Teléfono"
          name="corporatePhone"
          type='number'
          value={corporatePhone}
          handleChange={handleChange}
          handleOnBlur={handleOnBlur}
          handleOnFocus={handleOnFocus}
        />

        {/* 9. Orden de compra */}
        <CoTextField
          label="Orden de compra (opcional)"
          name="purchaseOrder"
          value={purchaseOrder}
          handleChange={handleChange}
        />
        <div className="dialog-btn-wrapper">
          <CoButton
            disabled={!isValidForm}
            isLoading={isLoading}
            handleClick={handleSubmit}
          >
            Guardar datos
          </CoButton>
        </div>
      </div>
    </CoDialog>
  );
};

export default Invoice;
