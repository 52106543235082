import TagManager from "react-gtm-module";
import helpers from "./helpers";
import { Data, Item } from "./IOrderForm";

const processProducts = (products: any) => {
  if (!products) return [];
  let productsToMark: {
    name: any;
    id: any;
    price: any;
    quantity: any;
    brand: any;
    category: string;
    dimension10: string;
    dimension11: string;
  }[] = [];
  products.forEach((product: any) => {
    let descuento: any = 0;
    descuento = product.price - product.sellingPrice;
    if (descuento > 0) descuento = descuento.toString().slice(0, -2);

    productsToMark.push({
      name: product.name || "",
      id: product.id || "",
      price: product.sellingPrice.toString().slice(0, -2) || "",
      quantity: product.quantity || "",
      brand: product.additionalInfo.brandName || "",
      category: Object.values(product?.productCategories).join("/") || "",
      dimension11: descuento < 0 ? 0 : descuento || 0,
      dimension10: descuento > 0 ? "con oferta" : "sin oferta",
    });
  });

  return productsToMark;
};

const step2ChekcoutMark = (orderForm: any) => {
  const productsToMark = processProducts(orderForm?.items);

  const mark = {
    dataLayer: {
      event: "checkout",
      ecommerce: {
        checkout: {
          actionField: {
            step: 2,
            option: "Datos Personales",
          },
          products: productsToMark,
        },
      },
      orderForm: {
        cartQuantity: orderForm?.items?.length,
        city: orderForm?.shippingData?.selectedAddresses[0]?.city,
        isDisposable: orderForm?.shippingData?.selectedAddresses[0]?.isDisposable,
        neighborhood: orderForm?.shippingData?.selectedAddresses[0]?.neighborhood,
        notAvailableItems: orderForm?.notAvailableItems,
        salesChannel: helpers.getCookie("co_sc"),
        state: orderForm?.shippingData?.selectedAddresses[0]?.state,
        totalizers: orderForm?.totalizers,
        userProfileId: orderForm?.userProfileId
      }
    },
  };

  TagManager.dataLayer(mark);
};

const paginaVirtualCheckoutMark = (orderForm: any) => {
  const mark = {
    dataLayer: {
      event: "pagina-virtual",
      page: "/checkout/datos-personales",
      pagename: "Checkout - datos personales",
      userID: orderForm.userProfileId,
    },
  };
  TagManager.dataLayer(mark);
};

const step4CheckoutMark = (orderForm: any, typeCard: any, card: any) => {
  const productsToMark = processProducts(orderForm?.items);

  const shippingDate = new Date(
    orderForm.shippingData.logisticsInfo[0].slas[0].deliveryWindow.startDateUtc
  );
  const endUtc = new Date(
    orderForm.shippingData.logisticsInfo[0].slas[0].deliveryWindow.endDateUtc
  );

  TagManager.dataLayer({
    dataLayer: {
      event: "checkout",
      "shipping-date": `${shippingDate.getUTCFullYear()}-${
        shippingDate.getUTCMonth() < 10
          ? "0" + shippingDate.getUTCMonth()
          : shippingDate.getUTCMonth()
      }-${
        shippingDate.getUTCDate() < 10
          ? "0" + shippingDate.getUTCDate()
          : shippingDate.getUTCDate()
      }`,
      "shipping-hour": `${
        shippingDate.getUTCHours() < 10
          ? "0" + shippingDate.getUTCHours()
          : shippingDate.getUTCHours()
      }:${
        shippingDate.getUTCMinutes() < 10
          ? "0" + shippingDate.getUTCMinutes()
          : shippingDate.getUTCMinutes()
      }:${
        shippingDate.getUTCSeconds() < 10
          ? "0" + shippingDate.getUTCSeconds()
          : shippingDate.getUTCSeconds()
      } - ${
        endUtc.getUTCHours() < 10
          ? "0" + endUtc.getUTCHours()
          : endUtc.getUTCHours()
      }:${
        endUtc.getUTCMinutes() < 10
          ? "0" + endUtc.getUTCMinutes()
          : endUtc.getUTCMinutes()
      }:${
        endUtc.getUTCSeconds() < 10
          ? "0" + endUtc.getUTCSeconds()
          : endUtc.getUTCSeconds()
      }`,

      "region-entrega": orderForm.shippingData.address.state,

      "ciudad-entrega": orderForm.shippingData.address.city,

      "comuna-entrega": orderForm.shippingData.address.neighborhood,

      "tipo-documento": "boleta",

      "medio-pago": card.name,

      "forma-entrega": orderForm.shippingData.logisticsInfo[0].selectedSla,
      ecommerce: {
        checkout: {
          actionField: { step: 3, option: "Pago" },
          products: productsToMark,
        },
      },
    },
  });

  TagManager.dataLayer({
    dataLayer: {
      event: "pagina-virtual",
      page: "/checkout/pago",
      pagename: "Checkout - pago",
      userID: orderForm.userProfileId,
    },
  });
};

const acceptPaymentError = (action: any, code: any) => {
  type codeOptions = {
    [key: string]: string;
  };
  const CODE_CASES: codeOptions = {
    "-1": "Modal verifica datos ingresados",
    "-2": "Modal transaccion fallida",
    "-4": "Modal transaccion no autorizada",
    "-5": "Modal transaccion fallida sin intento",
    "-96": "Modal transaccion fallida eliminar tarjeta",
    "-97": "Modal transaccion fallida monto diario excedido",
    "-98": "Modal transaccion fallida monto pedido excedido",
    "-99": "Modal transacciones diarias alcanzadas",
    "-100": "Modal transaccion anulada"
  };
  const eventCategory = CODE_CASES[code] || null;
  const mark = {
    dataLayer: {
      event: "evento-interactivo",
      "evento-interactivo-categoria": eventCategory,
      "evento-interactivo-accion": "Click",
      "evento-interactivo-etiqueta": action,
    },
  };
  if (eventCategory != null) TagManager.dataLayer(mark)
};

const modalPaymentError = (code: any, userId: any) => {
  type codeOptions = {
    [key: string]: object;
  };
  const CODE_CASES: codeOptions = {
    "-1": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-verifica-datos-ingresados",
        pagename: "Checkout - error - modal verifica datos ingresados",
        userID: userId,
      },
    },
    "-2": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida",
        pagename: "Checkout - error - modal transaccion fallida",
        userID: userId,
      },
    },
    "-4": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-no-autorizada",
        pagename: "Checkout - error - transaccion no autorizada",
        userID: userId,
      },
    },
    "-5": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-sin-intento",
        pagename: "Checkout - error - modal transaccion fallida sin intento",
        userID: userId,
      },
    },
    "-96": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-eliminar-tarjeta",
        pagename:
          "Checkout - error - modal transaccion fallida eliminar tarjeta",
        userID: userId,
      },
    },
    "-97": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-monto-diario-excedido",
        pagename:
          "Checkout - error - modal transaccion fallida monto diario excedido",
        userID: userId,
      },
    },
    "-98": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transaccion-fallida-monto-pedido-excedido",
        pagename:
          "Checkout - error - modal transaccion fallida monto pedido excedido",
        userID: userId,
      },
    },
    "-99": {
      dataLayer: {
        event: "pagina-virtual",
        page: "/checkout/error/modal-transacciones-diarias-alcanzadas",
        pagename: "Checkout - error - modal transacciones diarias alcanzadas",
        userID: userId,
      },
    },

  };
  const dataSet = CODE_CASES[code] || null;

  if (dataSet != null) {
    TagManager.dataLayer(dataSet);
  }
};

const modalRedirectPayment = (userId: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "pagina-virtual",
      page: "/checkout/redireccion-medio-pago",
      pagename: "Checkout - redireccion al medio de pago",
      userID: userId.userProfileId,
    },
  });
};

const changeDirectionHour = (action: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "evento-interactivo",
      "evento-interactivo-categoria": "Interacciones checkout",
      "evento-interactivo-accion": "Click",
      "evento-interactivo-etiqueta": action,
    },
  });
};

const modalChangeDirection = (orderForm: Data) => {
  const { neighborhood } = orderForm?.shippingData?.address
  TagManager.dataLayer({
    dataLayer: {
      event: "store_locator_cambiar",
      "event_action": "cambiar dirección",
      "event_category": "Checkout",
      "event_label": `Alvi ${neighborhood}`
    }
  })
}

const clickChangeDirection = (shippingMethod: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "eventos_store_locator",
      "event_action": "cambiar_metodo_entrega",
      "event_category": "Checkout",
      "event_label": shippingMethod === 'delivery' ? 'Despacho a domicilio' : 'Retiro en tienda'
    }
  })
}

const modalWithoutStock = (products: any) => {
  let formatedProducts: any = [];

  products.map((e: any, index: any) => {
    formatedProducts.push({
      name: e.name,
      id: e.id,
      price: e.price,
      quantity: e.quantity,
      brand: e.additionalInfo.brandName,
      category: e.productCategories,
      position: index + 1,
      list: "Checkout - Productos no disponibles - mal catalogados",
    });
  });

  TagManager.dataLayer({
    dataLayer: {
      event: "ecommerce",
      impressions: formatedProducts,
    },
  });
};

const modalWithoutStockAction = (action: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "evento-interactivo",
      "evento-interactivo-categoria":
        "Modal productos no disponibles - mal catalogados",
      "evento-interactivo-accion": "Click",
      "evento-interactivo-etiqueta": action,
    },
  });
};

interface InteractiveEventProps {
  action: string;
  category?: string;
  event?: string;
  label: string | void;
  cardType?: string;
  paymentType?: string;
}

const interactiveEvent = (dataLayerInfo: InteractiveEventProps) => {
  TagManager.dataLayer({
    dataLayer: {
      'event': dataLayerInfo.event || 'interacciones_checkout',
      'event_category': dataLayerInfo.category || 'checkout',
      'evento_action': dataLayerInfo.action,
      'evento_label': dataLayerInfo.label,
      'card_type': dataLayerInfo.cardType || '',
      'payment_type': dataLayerInfo.paymentType || ''
    },
  });
};

const arrangedProductList = ({product, index}: {product: Item, index: number}) => {
  const defaultPrice = product?.listPrice / (product?.unitMultiplier * 100)
  return {
    'precio-lista': defaultPrice,
    ahorro: 0,
    index: index,
    item_brand: product?.additionalInfo?.brandName,
    item_id: product?.productId,
    item_list_id: 'checkout',
    item_list_name: 'checkout',
    item_name: product?.name,
    oferta: 0,
    price: defaultPrice,
    quantity: product?.quantity ?? 1,
    stock: 1
  }
}

const addShipingInfo = (orderForm: Data) => {
  const isDelivery = orderForm.shippingData?.selectedAddresses[0]?.addressType === 'residential'
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_shipping_info',
      currency: 'CLP',
      value: orderForm?.value / 100,
      shipping_tier: isDelivery ? 'Despacho a domicilio' : 'Retiro en tienda',
      assigned_store: `${orderForm?.salesChannel} Alvi - ${orderForm?.shippingData?.address?.neighborhood}`,
      orderform_id: orderForm?.orderFormId,
      user_id: orderForm?.userProfileId,
      ecommerce: {
        items: orderForm?.items.map((product: Item, index: number) => {
          return arrangedProductList({product, index: index + 1})
        })
      },
    },
  });
};

interface AddPaymentInfoProps {
  orderForm: Data;
  paymentType: string | void;
  typeCard: string | void;
  context?: string;
}

const addPaymentInfo = ({
  orderForm,
  paymentType,
  typeCard,
  context,
}: AddPaymentInfoProps) => {
  const totalizer = orderForm?.totalizers
  const items = totalizer?.items?.map((product: Item, index: number) => {
    return arrangedProductList({product, index: index + 1})
  })
  const itemsSecondOption = orderForm?.items?.map((product: Item, index: number) => {
    return arrangedProductList({product, index: index + 1})
  })
  const isDelivery = orderForm.shippingData?.selectedAddresses[0]?.addressType === 'residential'
  TagManager.dataLayer({
    dataLayer: {
      event: 'add_payment_info',
      assigned_store: `${orderForm?.salesChannel} Alvi - ${orderForm?.shippingData?.address?.neighborhood}`,
      card_type: typeCard,
      context: context,
      coupon: orderForm?.marketingData?.coupon,
      currency: 'CLP',
      orderform_id: orderForm?.orderFormId,
      payment_type: paymentType,
      shipping_tier: isDelivery ? 'Despacho a domicilio' : 'Retiro en tienda',
      user_id: orderForm?.userProfileId,
      value: orderForm?.value / 100,
      ecommerce: {
        items: items ? items : itemsSecondOption
      },
    },
  });
};


export default {
  acceptPaymentError,
  addPaymentInfo,
  addShipingInfo,
  clickChangeDirection,
  changeDirectionHour,
  interactiveEvent,
  modalChangeDirection,
  modalPaymentError,
  modalRedirectPayment,
  modalWithoutStock,
  modalWithoutStockAction,
  paginaVirtualCheckoutMark,
  step2ChekcoutMark,
  step4CheckoutMark
}